<template>
  <b-container>
    <b-card>
      <b-card-title class="text-center mb-5" :title="$t('ticket.link')"></b-card-title>
      <GoldflamTable
        :new-item="newTicketObject"
        :all-items="filteredTickets"
        :table-fields="tableFields"
        sort-by="ticketId"
        :edit-function="editTicketFunction"
        :merge-function="mergeTicketsFunction"
        :delete-function="deleteTicket"
        :delete-warning="deleteModalWarningMessage"
        :delete-modal-prevent-close="true"
        :busy="datatableBusy"
      >
        <template v-slot:cell(ticketId)="row">
          <b-link
            v-if="row.item.jiraBaseUrl"
            title="Open this ticket in Jira"
            target="_blank"
            :href="getTicketUrl(row.item)"
            v-ellipsis:bottom="row.value"
          >
            {{ row.item.ticketId }}
          </b-link>
          <span v-else>{{ row.item.ticketId }}</span>
        </template>
        <template v-slot:filters>
          <b-col cols="3">
            <b-select id="customerFilter" v-model="filter.customer" :options="customerOptions">
              <template #first>
                <b-form-select-option :value="null">{{ $t('general.all-customers') }}</b-form-select-option>
              </template>
            </b-select>
          </b-col>
          <b-col cols="3">
            <b-select id="projectFilter" v-model="filter.project" :options="projectOptionsSortedFiltered">
              <template #first>
                <b-form-select-option :value="null">{{ $t('general.all-projects') }}</b-form-select-option>
              </template>
            </b-select>
          </b-col>
          <b-col cols="2">
            <b-select id="ticketStatusFilter" v-model="filter.status" :options="filterStatusOptions">
              <template #first>
                <b-form-select-option :value="null">{{ $t('general.all-status') }}</b-form-select-option>
              </template>
            </b-select>
          </b-col>
        </template>
      </GoldflamTable>
      <b-modal
        id="editModal"
        v-model="modalControl.edit.show"
        size="lg"
        centered
        :title="$t('timetracking.edit-ticket')"
        :ok-title="$t('general.save')"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="saveTicket(editTicket)"
        :ok-disabled="$v.$invalid"
        @hidden="resetEditModal"
      >
        <div v-if="modalControl.edit.loading" class="d-flex justify-content-center my-4">
          <b-spinner />
        </div>
        <b-form v-else>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editProject"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.project')"
          >
            <b-form-select
              id="editProject"
              @change="loadParentTicketSuggestions(editTicket, $event.id)"
              :options="projectOptionsSortedFiltered"
              v-model="$v.editTicket.project.$model"
              :state="validateFormInput('project')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editName"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.ticketId')"
          >
            <b-form-input
              id="editTicketId"
              type="text"
              v-model="$v.editTicket.ticketId.$model"
              :state="validateFormInput('ticketId')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editName"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.ticket')"
          >
            <b-form-input
              id="editName"
              type="text"
              v-model="$v.editTicket.name.$model"
              :state="validateFormInput('name')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editTicketStatus"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.status')"
            :description="ticketStatusDescription"
          >
            <b-form-select id="editTicketStatus" type="text" v-model="editTicket.status" :options="statusOptions" />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editName"
            label-cols="4"
            label-cols-lg="2"
            :label="'Parent Ticket'"
          >
            <vue-typeahead-bootstrap
              :disabled="!editTicket.project || !editTicket.project.id || editTicket.isParentTicket"
              :placeholder="$t('ticket.parent-ticket-typeahead-placeholder')"
              ref="searchParentTicketsDropdown"
              v-model="parentTicketSuggestionQuery"
              @hit="editTicket.parentTicketId = $event.id"
              :data="parentTicketSuggestions"
              :showOnFocus="true"
              :serializer="
                parentTicket => (parentTicket.ticketId ? parentTicket.ticketId + ' ' : '') + parentTicket.name
              "
              class="flex-fill"
              :maxMatches="parentTicketSuggestionQuery.length > 0 ? parentTicketSuggestions.length : 20"
            >
              <template v-slot:append>
                <b-button v-show="!!editTicket.parentTicketId" id="start-now" @click="clearParentTicket">
                  <b-icon icon="x" />
                </b-button>
              </template>
            </vue-typeahead-bootstrap>
          </b-form-group>
        </b-form>
      </b-modal>
      <b-modal
        id="mergeModal"
        v-model="modalControl.merge.show"
        size="xl"
        centered
        :title="$t('ticket.merge-title')"
        :ok-title="$t('ticket.merge-title')"
        ok-variant="warning"
        :ok-disabled="isSameTicket || !ticketsSelected || ticketsHaveOpenChildTickets"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="mergeTickets(merge.ticketA, merge.ticketB)"
      >
        <b-container>
          <b-row class="mb-4">
            <b-col>{{ $t('ticket.merge-select') }}</b-col>
            <b-col>
              <b-select :options="ticketOptions" @change="loadMergeTicketA($event.id)" />
            </b-col>
            <b-col>
              <b-select :options="ticketOptions" @change="loadMergeTicketB($event.id)" />
            </b-col>
          </b-row>

          <b-form>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="mergeTicketId" :label="$t('general.ticketId')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input id="mergeTicketName" type="text" v-model="merge.ticketA.ticketId" :disabled="true" />
              </b-col>
              <b-col>
                <b-form-input id="mergeTicketName" type="text" v-model="merge.ticketB.ticketId" :disabled="true" />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="mergeTicketName" :label="$t('general.ticketName')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input id="mergeTicketName" type="text" v-model="merge.ticketA.name" :disabled="true" />
              </b-col>
              <b-col>
                <b-form-input id="mergeTicketName" type="text" v-model="merge.ticketB.name" :disabled="true" />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="mergeTicketProjects" :label="$t('general.project')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input
                  id="mergeTicketProjects"
                  type="text"
                  v-model="merge.ticketA.project.fullName"
                  :disabled="true"
                />
              </b-col>
              <b-col>
                <b-form-input
                  id="mergeTicketProjects"
                  type="text"
                  v-model="merge.ticketB.project.fullName"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-form-group label-for="mergeTicketCustomer" :label="$t('general.customer')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input
                  id="mergeTicketCustomer"
                  type="text"
                  v-model="merge.ticketA.project.customer.fullName"
                  :disabled="true"
                />
              </b-col>
              <b-col>
                <b-form-input
                  id="mergeTicketCustomer"
                  type="text"
                  v-model="merge.ticketB.project.customer.fullName"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4" />
              <b-col sm="8">
                <b-alert :show="ticketsSelected" variant="info">
                  {{ $t('ticket.merge-modal-message', [this.merge.ticketA.name, this.merge.ticketB.name]) }}
                </b-alert>
                <b-alert :show="ticketsBelongToDifferentProjects" variant="warning">
                  {{ $t('ticket.merge-modal-warning') }}
                </b-alert>
                <b-alert :show="isSameTicket" variant="danger">
                  {{ $t('ticket.merge-identical-msg') }}
                </b-alert>
                <b-alert :show="ticketsHaveOpenChildTickets" variant="danger">
                  {{ $t('ticket.merge-child-tickets-msg') }}
                </b-alert>
              </b-col>
            </b-row>
          </b-form>
        </b-container>
      </b-modal>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import GoldflamTable from '@/components/GoldflamTable'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { GoldflamTicketIdOptional } from '@/util/VuelidateValidators'
import { cloneDeep as _cloneDeep, isEmpty as _isEmpty } from 'lodash'
import { StaticSelectOpts } from '@/constants/StaticSelectOpts'
import { i18n } from '@/plugins/i18n'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

export default {
  name: 'ManageTickets',
  mixins: [ApiMixin, NotificationMixin, validationMixin],
  components: { GoldflamTable, VueTypeaheadBootstrap },

  data() {
    return {
      objectModels: {
        ticket: {
          id: null,
          name: '',
          ticketId: '',
          project: {
            id: '',
            fullName: '',
            customer: {
              fullName: ''
            }
          },
          parentTicketId: null,
          isParentTicket: false,
          hasOpenChildTickets: false
        }
      },
      editTicket: {},
      merge: {
        ticketA: {},
        ticketB: {}
      },
      modalControl: {
        edit: {
          show: false,
          loading: false
        },
        delete: {
          show: false
        },
        merge: {
          show: false
        }
      },
      allTickets: [],
      allProjects: [],
      allCustomers: [],
      parentTicketSuggestions: [],
      parentTicketSuggestionQuery: '',
      tableFields: [
        { key: 'ticketId', label: this.$t('general.ticketId'), sortable: true },
        { key: 'name', label: this.$t('general.ticketName'), sortable: true },
        { key: 'projectName', label: this.$t('general.project'), sortable: true },
        { key: 'customerName', label: this.$t('general.customer'), sortable: true },
        { key: 'status', label: this.$t('general.status'), sortable: true, formatter: 'formatTicketStatus' },
        { key: 'crudActions', label: this.$t('general.table.actions'), width: '100px' }
      ],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      datatableBusy: false,
      filter: {
        customer: null,
        project: null,
        status: 'OPEN'
      },
      filterStatusOptions: StaticSelectOpts.ticketStatusOptions,
      statusOptions: StaticSelectOpts.ticketStatusOptions,
      showDeleteWarning: false,
      deleteModalWarningMessage: null
    }
  },
  validations: {
    editTicket: {
      name: {
        required
      },
      ticketId: {
        GoldflamTicketIdOptional
      },
      project: {
        id: {
          required
        }
      }
    }
  },
  created() {
    this.editTicket = _cloneDeep(this.objectModels.ticket)
    this.merge.ticketA = _cloneDeep(this.objectModels.ticket)
    this.merge.ticketB = _cloneDeep(this.objectModels.ticket)
  },
  mounted() {
    this.datatableBusy = true
    this.loadAllTickets().finally(() => {
      this.datatableBusy = false
    })
    // async loading of projects and customers as these are not required for datatable rendering
    this.loadProjectList()
    this.loadCustomerList()
  },
  watch: {
    'editTicket.allTTUsReviewed'() {
      this.ticketStatusDescription()
    },
    'editTicket.project'(newProject, oldProject) {
      if (this.editTicket.id && oldProject.id === '') {
        // prevent overwriting existing ticketId when opening edit modal
        return
      }
      if (newProject !== null && newProject.id !== '') {
        if (newProject.ticketIdPrefix !== null) {
          this.editTicket.ticketId = newProject.ticketIdPrefix
          if (newProject.ticketIdPrefix !== '#') {
            this.editTicket.ticketId += '-'
          }
        } else {
          this.editTicket.ticketId = ''
        }
      }
    }
  },
  computed: {
    parentTicketOptions() {
      let text = ''
      if (!this.editTicket.project || !this.editTicket.project.id) {
        text = this.$t('ticket.parent-ticket-info-1')
      } else {
        if (this.editTicket.parentTicketId) {
          text = this.$t('ticket.parent-ticket-info-3')
        } else {
          text = this.$t('ticket.parent-ticket-info-2')
        }
      }
      if (this.editTicket.isParentTicket) {
        text = this.$t('ticket.parent-ticket-info-4')
      }
      let options = [
        {
          value: null,
          text: text
        }
      ]
      this.parentTicketSuggestions.forEach(ticket => {
        let ticketName = ''
        if (ticket.ticketId) {
          ticketName = ticket.ticketId + ' - ' + ticket.name
        } else {
          ticketName = ticket.name
        }
        if (this.editTicket.id) {
          ticketName = ticketName + ' (' + this.$t('ticket.status.' + ticket.status.toLowerCase()) + ')'
        }
        if (this.editTicket.id !== ticket.id && ticket.parentTicketId === null) {
          options.push({
            value: ticket.id,
            text: ticketName
          })
        }
      })
      return options
    },
    projectOptionsSorted() {
      return this.allProjects
        .map(project => {
          return {
            value: project,
            text: project.fullName + ' (' + project.customer.fullName + ')'
          }
        })
        .sort((a, b) => a.text.localeCompare(b.text))
    },
    projectOptionsSortedFiltered() {
      if (this.filter.customer !== null) {
        return this.projectOptionsSorted.filter(projectOpt => projectOpt.value.customer.id === this.filter.customer.id)
      } else {
        return this.projectOptionsSorted
      }
    },
    customerOptions() {
      return this.allCustomers
        .map(customer => {
          return { value: customer, text: customer.fullName }
        })
        .sort((a, b) => a.text.localeCompare(b.text))
    },
    ticketOptions() {
      if (this.allTickets) {
        return this.allTickets
          .map(ticket => {
            return {
              value: ticket,
              text:
                (ticket.ticketId ? ticket.ticketId + ' ' : '') +
                ticket.name +
                ' (' +
                ticket.projectName +
                ' / ' +
                ticket.customerName +
                ')'
            }
          })
          .sort((a, b) => a.text.localeCompare(b.text))
      } else {
        return []
      }
    },
    ticketsSelected() {
      return this.merge.ticketA.id !== null && this.merge.ticketB.id !== null
    },
    isSameTicket() {
      return this.merge.ticketA.id === this.merge.ticketB.id
    },
    ticketsBelongToDifferentProjects() {
      if (this.merge.ticketA.project.fullName === '' || this.merge.ticketB.project.fullName === '') {
        return false
      } else {
        return (
          this.merge.ticketA.project.fullName !== this.merge.ticketB.project.fullName &&
          this.merge.ticketA.project.id !== this.merge.ticketB.project.id
        )
      }
    },
    ticketsHaveOpenChildTickets() {
      return this.merge.ticketA.hasOpenChildTickets || this.merge.ticketB.hasOpenChildTickets
    },
    filteredTickets() {
      let tickets = this.allTickets || []
      let filterByProject = this.filter.project !== null && this.filter.project !== ''
      let filterByCustomer = this.filter.customer !== null && this.filter.customer !== ''
      let filterByStatus = this.filter.status !== null
      tickets = tickets.filter(t => {
        if (filterByProject && t.projectId !== this.filter.project.id) {
          return false
        }
        if (filterByCustomer && t.customerId !== this.filter.customer.id) {
          return false
        }
        return !(filterByStatus && t.status !== this.filter.status)
      })
      return tickets
    },
    newTicketObject() {
      let newTicket = _cloneDeep(this.objectModels.ticket)
      newTicket.status = 'OPEN'
      if (this.filter.project) {
        newTicket.project = this.filter.project
      }
      return newTicket
    },
    ticketStatusDescription() {
      if (this.editTicket.allTTUsReviewed === undefined || this.editTicket.allTTUsReviewed) {
        if (this.editTicket.hasOpenChildTickets) {
          return this.$t('ticket.disabled-description-1')
        }
        return ''
      } else if (this.editTicket.hasOpenChildTickets) {
        return this.$t('ticket.disabled-description-2')
      } else {
        return this.$t('ticket.disabled-description-2')
      }
    }
  },
  methods: {
    getTicketUrl(item) {
      let id = item.ticketId
      if (id.startsWith('#')) {
        // that's probably a Gitlab ticket, we strip the #-prefix
        return item.jiraBaseUrl + id.substr(1)
      } else {
        return item.jiraBaseUrl + id
      }
    },
    validateFormInput(prop) {
      const { $dirty, $invalid } = this.$v.editTicket[prop]
      return $dirty ? !$invalid : null
    },
    loadAllTickets() {
      let self = this
      return this.getRequest(
        '/tickets/simple?onlyOpenProjects=true',
        new RequestConfig().onSuccess(res => {
          self.allTickets = res.data
        })
      )
    },
    async loadParentTicketSuggestions(ticket, projectId) {
      this.clearParentTicket()
      if (!ticket.id) {
        if (projectId) {
          await this.loadAllOpenTicketsForProject(projectId)
        }
      } else {
        await this.loadAllTicketsForProjectAndExcludeTicketId(projectId, ticket.id)
      }
    },
    loadAllTicketsForProjectAndExcludeTicketId(projectId, excludeTicketId) {
      let self = this
      return this.getRequest(
        `/tickets/parentTicketSuggestions?projectId=${projectId}&excludedTicketId=${excludeTicketId}`,
        new RequestConfig().onSuccess(res => {
          self.parentTicketSuggestions = res.data
        })
      )
    },
    loadAllOpenTicketsForProject(projectId) {
      let self = this
      return this.getRequest(
        `/tickets/parentTicketSuggestions?projectId=${projectId}&onlyOpenTickets=${true}`,
        new RequestConfig().onSuccess(res => {
          self.parentTicketSuggestions = res.data
        })
      )
    },
    saveTicket(ticket) {
      let url = '/tickets'
      if (ticket.id) {
        url += `/${ticket.id}`
      }
      let self = this
      this.postRequest(
        url,
        ticket,
        new RequestConfig()
          .onSuccess(() => {
            self.displaySuccess(self.$t('ticket.success-msg'))
            self.loadAllTickets()
          })
          .onError(err => {
            if (err.response.data.message === 'ticket-already-exists') {
              self.displayError(self.$t('ticket.already-exists'))
            } else {
              self.displayError(err.response.data.message)
            }
          })
      )
    },
    deleteTicket(ticket, modalId) {
      let self = this
      const id = ticket.id
      const forceDelete = self.showDeleteWarning
      self.showDeleteWarning = false
      this.deleteRequest(
        `/tickets/${id}?forceDelete=${forceDelete}`,
        new RequestConfig()
          .onSuccess(() => {
            self.displaySuccess(this.$t('ticket.delete-success-msg'))
            self.loadAllTickets()
            self.hideDeleteModalManually(modalId)
            self.showDeleteWarning = false
            self.deleteModalWarningMessage = null
          })
          .onError(err => {
            if (err.response.data.message === 'ticket-has-review-ttu') {
              self.displayError(this.$t('ticket.delete-error-msg-has-review-ttu'))
              self.hideDeleteModalManually(modalId)
            } else if (err.response.data.message === 'ticket-has-ttu') {
              self.showDeleteWarning = true
              self.deleteModalWarningMessage = self.$t('ticket.delete-warning-msg-has-ttu')
            } else {
              self.displayError(err.response.data.message)
              self.hideDeleteModalManually(modalId)
            }
          })
      )
    },
    hideDeleteModalManually(modalId) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
      })
    },
    async editTicketFunction(ticket) {
      // show modal immediately in this special case and set loading=true to display the spinner inside the modal until subsequent api calls are finished
      this.modalControl.edit.show = true
      this.modalControl.edit.loading = true

      await this.loadParentTicketSuggestions(ticket, ticket.projectId)
      if (ticket.id) {
        this.editTicket = await this.loadTicket(ticket.id)
        // avoid traversing forEach and use faster findIndex
        const parentTicketSuggestionIndex = this.parentTicketSuggestions.findIndex(
          parentTicket => parentTicket.id === this.editTicket.parentTicketId
        )
        if (parentTicketSuggestionIndex > 0) {
          const parentTicket = this.parentTicketSuggestions[parentTicketSuggestionIndex]
          this.parentTicketSuggestionQuery =
            (parentTicket.ticketId ? parentTicket.ticketId + ' ' : '') + parentTicket.name
        }
      } else {
        this.editTicket = _cloneDeep(ticket)
      }
      if (ticket.id !== null && this.editTicket.id !== null) {
        await this.areAllTimeTrackingUnitsOfTicketReviewed(this.editTicket)
      }

      // reset loading state
      this.modalControl.edit.loading = false
    },
    clearParentTicket() {
      this.editTicket.parentTicketId = null
      this.parentTicketSuggestionQuery = ''
    },
    loadTicket(ticketId) {
      return this.getRequest(
        `/tickets/${ticketId}`,
        new RequestConfig().onSuccess(res => {
          return res.data
        })
      )
    },
    async loadMergeTicketA(ticketId) {
      this.merge.ticketA = await this.loadTicket(ticketId)
    },
    async loadMergeTicketB(ticketId) {
      this.merge.ticketB = await this.loadTicket(ticketId)
    },
    loadProjectList() {
      let self = this
      return this.getRequest(
        '/projects?onlyOpen=true',
        new RequestConfig().onSuccess(res => {
          self.allProjects = res.data
        })
      )
    },
    loadCustomerList() {
      let self = this
      return this.getRequest(
        '/customers/simple',
        new RequestConfig().onSuccess(res => {
          self.allCustomers = res.data
        })
      )
    },
    mergeTickets(ticketA, ticketB) {
      const idA = ticketA.id
      const idB = ticketB.id
      let self = this
      this.postRequest(
        `/tickets/${idA}/merge/${idB}`,
        {},
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('ticket.merge-success-msg'))
          self.loadAllTickets()
        })
      )
    },
    async mergeTicketsFunction(ticketA, ticketB) {
      if (ticketA.id) {
        this.merge.ticketA = await this.loadTicket(ticketA.id)
        this.merge.ticketB = Object.assign({}, ticketB)
        this.modalControl.merge.show = true
      }
    },
    resetEditModal() {
      this.modalControl.edit.show = false
      this.modalControl.edit.loading = false
      this.editTicket = _cloneDeep(this.objectModels.ticket)
      this.parentTicketSuggestionQuery = ''
      this.$v.$reset()
    },
    async areAllTimeTrackingUnitsOfTicketReviewed(ticket) {
      const ticketId = ticket.id
      let self = this
      await this.getRequest(
        `/tickets/${ticketId}/all_ttus_reviewed`,
        new RequestConfig().onSuccess(async res => {
          self.editTicket.allTTUsReviewed = res.data
          await self.loadTicketStatusOptions()
        })
      )
    },
    loadTicketStatusOptions() {
      if (
        !_isEmpty(this.editTicket) &&
        Object.prototype.hasOwnProperty.call(this.editTicket, 'allTTUsReviewed') &&
        (!this.editTicket.allTTUsReviewed || this.editTicket.hasOpenChildTickets)
      ) {
        this.statusOptions = [
          { value: 'OPEN', text: i18n.t('ticket.status.open') },
          {
            value: 'CANCELLED',
            text: i18n.t('ticket.status.cancelled'),
            disabled: !this.editTicket.allTTUsReviewed || this.editTicket.hasOpenChildTickets
          },
          {
            value: 'FINISHED',
            text: i18n.t('ticket.status.finished'),
            disabled: !this.editTicket.allTTUsReviewed || this.editTicket.hasOpenChildTickets
          }
        ]
      } else {
        this.statusOptions = StaticSelectOpts.ticketStatusOptions
      }
    }
  }
}
</script>
